import React from 'react';
import MuiCustomTheme from './MuiCustomTheme'
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/captions.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";




const ThemeTopLevelProvider: React.FC<{children: React.ReactNode}> = ({ children }) => {
  return <MuiCustomTheme>{children}</MuiCustomTheme>
}

export default ThemeTopLevelProvider
