import React from 'react'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider, Theme as MaterialTheme, createTheme } from '@mui/material/styles'
import ResponsiveAppBar from '../../components/AppBar/AppBar';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { lightBlue, } from '@mui/material/colors'


const theme = createTheme({
  typography: {
    fontFamily: [
      'Open Sans',
      
      'Arial'
    ].join(','),
  },
  palette: {
    primary: {
      main: lightBlue[300]
    }
  },
});
const queryClient = new QueryClient()
const MuiCustomTheme: React.FC<{children: React.ReactNode}> = ({ children }) => {
  // take away SSR rendered mode;
  React.useEffect(() => {
    document.body.className = ''
  }, [])


  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <CssBaseline />
        <ResponsiveAppBar />
        {children}
      </QueryClientProvider>
    </ThemeProvider>
  )
}

export default MuiCustomTheme
