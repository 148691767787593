import { useStaticQuery, graphql } from "gatsby"

export const useSiteTitle = () => {
  const { site } = useStaticQuery<{site: {
    siteMetadata: {
      title: string
    }
  }}>(
    graphql`
      query SiteTitle {
        site {
          siteMetadata {
            title
          }
        }
      }
    `
  )
  return site.siteMetadata.title;
}