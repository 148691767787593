import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import { usePages } from './usePages';
import { Link } from 'gatsby';
import { useSiteTitle } from '../../hooks/useSiteTitle';

const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const pageNames = usePages()
  const siteTitle = useSiteTitle();

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <BeachAccessIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />
          <Typography
            component={Link}
            to={"/"}
            variant="h6"
            noWrap
            sx={{
              mr: 2,
              px: 1,
              display: { xs: 'none', md: 'flex' },
              fontWeight: 700,
              fontFamily: 'Caveat',
              letterSpacing: '0',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            {siteTitle}
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pageNames.map(({ pageTitle, id, slug }) => (
                <MenuItem key={id} onClick={handleCloseNavMenu}>
                    <Typography component={Link} to={`/${slug || ''}`} textAlign="center">{pageTitle}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <BeachAccessIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />
          <Typography
            component={Link}
            to={"/"}
            variant="h6"
            noWrap
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontWeight: 700,
              letterSpacing: '0',
              color: 'inherit',
              fontFamily: 'Caveat',
              textDecoration: 'none',
              alignContent: 'center',
              textAlign: 'center',
              wordWrap: 'break-word',
              textWrap: 'pretty',
            }}
          >
            {siteTitle}
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pageNames.map(({ pageTitle, id, slug }) => (
              <Button
                key={id}
                LinkComponent={Link}
                {...{ to: `/${slug || ''}` }}
                onClick={handleCloseNavMenu}
                color='inherit'
                sx={{ my: 2, display: 'block', fontFamily: 'Caveat', fontSize: '16px', fontWeight: 700 }}
              >
                {pageTitle}
              </Button>
            ))}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Button LinkComponent={'a'} href='tel:+18182160591' size='large' sx={{ textTransform: 'unset', fontFamily: 'Caveat', fontSize: '22px', fontWeight: 700 }} color='inherit' title='Book Now'>
              Book Now!
            </Button>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;
