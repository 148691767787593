import { useStaticQuery, graphql } from "gatsby"

export const usePages = () => {
  const { allContentfulPage } = useStaticQuery<{allContentfulPage: {
    nodes: {
      id: string
      slug: string | null
      pageTitle: string
      priority: number
    }[]
  }}>(
    graphql`
      query PageNames {
        allContentfulPage(sort: {priority: DESC}) {
          nodes{
            id
            slug
            pageTitle
            priority
          }
        }
      }
    `
  )
  return [...allContentfulPage.nodes, {id: "contact", slug: "contact", pageTitle: "Contact Us", priority: 0}];
}